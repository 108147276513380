

/**
 * @internal
 * @author ...
 */
export class VertexShuriKenParticle {
	//TODO Attribute
	static PARTICLE_DIRECTIONTIME: number = 0;
	static PARTICLE_POSITION0: number = 1;
	static PARTICLE_COLOR0: number = 2;
	static PARTICLE_TEXTURECOORDINATE0: number = 3;
	static PARTICLE_SHAPEPOSITIONSTARTLIFETIME: number = 4;
	static PARTICLE_CORNERTEXTURECOORDINATE0: number = 5;
	static PARTICLE_STARTCOLOR0: number = 6;
	static PARTICLE_ENDCOLOR0: number = 7;
	static PARTICLE_STARTSIZE: number = 8;
	static PARTICLE_STARTROTATION: number = 9;
	static PARTICLE_STARTSPEED: number = 10;
	static PARTICLE_RANDOM0: number = 11;
	static PARTICLE_RANDOM1: number = 12;
	static PARTICLE_SIMULATIONWORLDPOSTION: number = 13;
	static PARTICLE_SIMULATIONWORLDROTATION: number = 14;
	static PARTICLE_SIMULATIONUV:number = 15;

	constructor() {

	}

}


