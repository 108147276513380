
export class TrailTextureMode {
	/**
	 * 拉伸模式。
	 */
	static Stretch: number = 0;

	/**
	 * 平铺模式。
	 */
	static Tile: number = 1;

}


